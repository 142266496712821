<template>
    <div class="">
        <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            type    = "xls"
            name    = "Customer.xls"
        />
        <div class=" p-1">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table class="table mb-0 h9 " id="header" cellpadding="4" cellspacing="0" border="0">
                            <thead>
                                <tr class="">
                                    <th width="60px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                    <th width="1px"></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-210) +'px;'">
                        <table class="table table-hover table-strip table-bordered">
                            <tr v-for="(dt, index) in recs" :key="index" class="w3-hover-pale-blue w3-small cursor" v-on:dblclick="showEdit(dt, index)" v-on:click="setKlikRow(index, dt)" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>Customer
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_name}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Alamat</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_address}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Phone</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_phone}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Lokasi</div>
                                    <div class="w3-col s8 m10 w3-theme-l4" v-html="setLokasi(dt.tc_lokasi)">
                                    </div>
                                </td>
                                <td width="60px" class="w3-hide-small w3-hide-medium w3-border-right" style="text-align:center">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                </td>
                                <td :width="findObjFind('width','tc_id')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_id,'tc_id')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_name')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_name,'tc_name')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_address')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_address,'tc_address')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_phone')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <span v-html="setTextFind(dt.tc_phone,'tc_phone')"></span>
                                </td>
                                <td :width="findObjFind('width','tc_lat')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.tc_lat}}/{{dt.tc_lng}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" style="padding: 3px 0px 0px 10px;" :style="'width:' + findObjFind('width','tc_lokasi')" class="w3-hide-small w3-hide-medium">
                                    <span v-html="setLokasi(dt.tc_lokasi)"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <div >
            <nav class="Page navigation h9 bg-light bg-gradient shadow">
                <div class="d-flex justify-content-center">
                    <ul class="pagination text-center">
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                        <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                        <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                        </ul>
                </div>
            </nav>
        </div>
        <div id="modal-form" class="modal w3-animate-zoom row col-md-5 top-50 start-50 translate-middle" style="max-width: 600px;">
                <div class="modal-dialog modal-dialog-scrollable">
                    <div class="modal-content w3-animate-zoom col-md-3">
                        <div class="modal-header pb-0">
                            <button onclick="document.getElementById('modal-form').style.display='none'"
                            class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Add / Edit data</p>
                        </div>
                        <div class="modal-body ">
                            <form class="container form-control border-0" id="frmCust" v-on:submit.prevent="saveData" autocomplete="off">
                                        <div class="row mb-3">
                                            <div class="col-md-3">
                                                <label for="" class="form-label">ID Customer</label>
                                                <input type="text" id="" class="form-control" v-model="rec.CustID" required>
                                            </div>
                                            <div class="col">
                                                <label for="" class="form-label">Nama Customer</label>
                                                <input type="text" class="form-control" v-model="rec.CustName" required>
                                            </div>
                                        </div>
                                        <div class="mb-3 row">
                                            <div class="col">
                                                <label for="" class="form-label">Alamat Usaha</label>
                                                <input type="text" class="form-control" v-model="rec.Address" required>
                                            </div>
                                            <div class="col-md-3">
                                                <label for="" class="form-label">Group</label>
                                                <select class="form-select" v-model="rec.GroupA_ID" required>
                                                    <option selected value="1">Surabaya</option>
                                                    <option value="50">Semarang</option>
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-4">
                                                <label for="" class="form-label">Fax</label>
                                                <input type="text" id="" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" v-model="rec.Fax">
                                            </div>
                                            <div class="col">
                                                <label for="" class="form-label">Phone</label>
                                                <input type="text" id="" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" v-model="rec.Phones">
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-5">
                                                <label for="" class="form-label">Tax ID</label>
                                                <input type="text" id="" class="form-control" v-model="rec.TaxID">
                                            </div>
                                            <div class="col">
                                                <label for="" class="form-label">Nama Tax</label>
                                                <input type="text" id="" class="form-control" v-model="rec.TaxName">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="form-label">Alamat Tax</label>
                                            <input type="text" class="form-control" v-model="rec.TaxAddress">
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-4">
                                                <label for="" class="form-label">Nama Sales</label>
                                                    <model-list-select
                                                        :list="sales"
                                                        class="w3-small"
                                                        option-value="ts_id"
                                                        option-text="ts_name"
                                                        v-model="rec.SalesRepID"
                                                        placeholder="Pilih Sales"
                                                        required
                                                    ></model-list-select>
                                            </div>
                                            <div class="col">
                                                <label for="" class="form-label">Credit Limit</label>
                                                <input type="text" id="" class="form-control" oninput="this.value = this.value.replace(/[^0-9]/g, '')" v-model="rec.CreditLimit">
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-4">
                                                <label for="" class="form-label">Nomer KTP</label>
                                                <input type="text" oninput="this.value = this.value.replace(/[^0-9]/g, '')" inputmode="numeric" id="" class="form-control" v-model="rec.PersonalID">
                                            </div>
                                            <div class="col">
                                                <label for="" class="form-label">Nama Pemilik</label>
                                                <input type="text" id="" class="form-control" v-model="rec.Owner_Name">
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="form-label">Nama Personal</label>
                                            <input type="text" class="form-control" v-model="rec.PersonalName">
                                        </div>
                                        <div class="mb-3">
                                            <label for="" class="form-label">Alamat Personal</label>
                                            <input type="text" class="form-control" v-model="rec.PersonalAddress">
                                        </div>
                                        <div class="row">
                                            <label for="" class="form-label">Pilih TOP</label>
                                            <div v-for="item in checkboxItems" :key="item.id" class="form-check col-md-6">
                                                <div class="form-switch ">
                                                    <input class="form-check-input" type="checkbox" :id="item.id" v-model="item.status" @change="handleCheckboxChange">
                                                    <label class="form-check-label" :for="item.id">{{ item.label }}</label>
                                                </div>
                                            </div>
                                          </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button form="frmCust" class="btn btn-success rounded" type="submit"><i class="fa fa-save"></i> Save</button>
                            <button type="button" class="btn btn-danger rounded" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
  import axios from "axios";
  import 'leaflet/dist/leaflet.css';
  import { ModelListSelect } from "vue-search-select";
  import "vue-search-select/dist/VueSearchSelect.css";
  import Toolbar from "@/components/Toolbar.vue";

  export default {
    name: 'Customer',
    components: {
      ModelListSelect,
      Toolbar
    },
    data() {
      return {
        screenHeight: 0,
        selectedRow: -1,
        rec: {},
        recs: [],
        pages: { halaman: 1, batas: 0, ttlhalaman: 0, ttlrec: 0, limit: Config.perpage, search: '' },
        filters: {
          operator: 'AND',
          fields: [
            { fieldFind: '', key: 'tc_id', type: 'text', name: 'ID', filter: true, width: '100px', grid: true },
            { fieldFind: '', key: 'tc_name', type: 'text', name: 'Name', filter: true, width: '200px', grid: true },
            { fieldFind: '', key: 'tc_address', type: 'text', name: 'Address', filter: true, width: '', grid: true },
            { fieldFind: '', key: 'tc_phone', type: 'text', name: 'Phone', filter: true, width: '100px', grid: true },
            { fieldFind: '', key: 'tc_lat', type: 'text', name: 'lat/lng', filter: true, width: '140px', grid: true },
            { fieldFind: '', key: 'tc_lng', type: 'text', name: 'lng', filter: true, width: '', grid: false },
            { fieldFind: '', key: 'tc_lokasi', type: 'select', name: 'Lokasi', filter: true, width: '80px', grid: true, valueFind: [{ key: 'S', label: 'Surabaya' }, { key: 'M', label: 'Semarang' }] },
          ],
          find: []
        },
        checkboxItems: [
          { id: '11', status: false, label: 'COD' },
          { id: '121', status: false, label: '7 Hari' },
          { id: '131', status: false, label: '14 Hari' },
          { id: '141', status: false, label: '30 Hari' },
          { id: '151', status: false, label: '45 Hari' },
          { id: '161', status: false, label: '60 Hari' },
        ],
        customers: [],
        sales: [],
        top: {},
      }
    },
    methods: {
      formatCreditLimit(value) {
        return new Intl.NumberFormat("id-ID").format(value);
      },
      findObjFind(key, val) {
        return this.findObj(this.filters.fields, 'key', val)[key];
      },
      findField(val) {
        return this.findObj(this.fieldRules, 'field', val);
      },
      setTextFind(txt, field) {
        var fieldvalue = "";
        var ketemu = false;
        var self = this;
        if (self.filters.find !== undefined)
          if (self.filters.find.length > 0) {
            self.filters.find.map(function (value) {
              if (value.fields === field) {
                ketemu = true;
              }
              if (fieldvalue !== 'null')
                fieldvalue += ((fieldvalue !== '') ? ';' : '') + value.fieldValue;
              else
                fieldvalue += ((fieldvalue !== 'null') ? ';' : '') + value.fieldValue;
            });
          }
        if (ketemu) {
          return self.sethighlight(txt, fieldvalue);
        } else {
          return txt;
        }
      },
      handleCheckboxChange() {
        this.top = this.checkboxItems
        .filter(item => item.status)
        .map(item => ({
            IdCust: this.rec.CustID,
            top: this.getTopValue(item.id),
            pfunction: 'tops'
        }));
        },
        getTopValue(id) {
            switch (id) {
            case '11':
                return 'COD';
            case '121':
                return '7d';
            case '131':
                return '14d';
            case '141':
                return '30d';
            case '151':
                return '60d';
            default:
                return 'COD';
            }
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        Cod() {
            this.top.push({
                IdCust: this.rec.IdCust,
                top: 'COD',
                ist_qty: '',
                g_id: '',
                pfunction: 'add',
                userentry: localStorage.username
            });
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'setcustomerpg',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("master/ApiCustomer.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;
            self.rec = {};
            this.checkboxItems.forEach(item => {
        item.status = false;
      });
            document.getElementById("modal-form").style.display = "block";

        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih customer.!');
                return false;
            }else{
                this.stsAdd = false;
                setTimeout(() => {
                    this.$refs.mymap.mapObject.invalidateSize();
                    this.setMap();
                }, 500);
                document.getElementById('modal-form').style.display='block';
            }
        },
        saveData(event) {
            var self = this;
            if (self.stsAdd == true) {
            self.rec.pfunction = "add";
            } else {
            self.rec.pfunction = "edit";
            }
            axios.post("master/ApiAddCust.php", self.rec).then(function (response) {
            if (response.data.success == true) {
                self.$toast.success(response.data.msg);
                self.saveTop(); // Memanggil fungsi saveTop()
                if (self.stsAdd) {
                self.recs.splice(0, 0, self.rec);
                } else {
                self.recs[self.selectedRow] = self.rec;
                }
                document.getElementById('modal-form').style.display = 'none';
                self.selectedRow = -1;
            } else {
                self.$toast.error(response.data.msg);
            }
            });
            event.preventDefault();
            return false;
        },
        saveTop() {
        var self = this;
        self.top.map(function(key) {
                axios.post("master/ApiAddCust.php",key).then(function (response) {
                    if(response.data.success == true){
                        console.log("Sukses", response.data.messages);
                    } else {
                        console.log("Gagal", response.data);
                    }
                });
            });
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih customer.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = {
                                tc_id : self.rec.tc_id,
                                pfunction : "delete"
                            };
                            axios.post("master/ApiCustomer.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    // self.$toast.success(response.data.msg);
                                    // self.$delete(self.recs, self.selectedRow);
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    // self.$toast.error(response.data.msg);
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        centerUpdate(center) {
            this.map.markerLatLng = center;
            this.rec.tc_lat = Number(center['lat']).toFixed(6);
            this.rec.tc_lng = Number(center['lng']).toFixed(6);
        },
        setCenterMarker(e){
            this.map.markerLatLng = e.latlng;
            this.map.center = e.latlng;
        },
        setMap(){
            this.map.markerLatLng = {
                lat : this.rec.tc_lat,
                lng : this.rec.tc_lng
            };
            this.map.center = {
                lat : this.rec.tc_lat,
                lng : this.rec.tc_lng
            };
        },
        moveCenter(){
            // console.log(e.target.options);
            // console.log(this.$refs.mymap.mapObject);
            // self.customers = response.data.rec;
        },
        setLokasi(dt) {
            if (dt === "S") {
                return "<div class='btn btn-success btn-sm' >Surabaya</div>";
            } else {
                return "<div class='btn btn-warning btn-sm' >Semarang<div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiSales.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.sales = response.data.rec;
            });
        }

    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>
